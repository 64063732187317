<h2 mat-dialog-title class="alert-dialog__title">
  {{ data.title || 'alert.title' | translate }}
</h2>
<mat-dialog-content class="alert-dialog__content">
  @for (line of data.message | translate | safeLineBreak; track $index) {
    <p>{{ line | translate }}</p>
  }
</mat-dialog-content>
<mat-dialog-actions align="center" class="alert-dialog__actions">
  <button mat-raised-button mat-dialog-close color="primary">
    {{ data.actionLabel || 'alert.actionLabel' | translate }}
  </button>
</mat-dialog-actions>
