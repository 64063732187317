/* src/app/shared/components/dump-components/alert-dialog/alert-dialog.component.scss */
.alert-dialog__title {
  text-align: center;
}
.alert-dialog__content {
  margin-top: 10px;
  text-align: center;
}
.alert-dialog__actions {
  padding: 0 20px;
  margin-bottom: 20px;
}
.alert-dialog__actions button {
  width: 100%;
}
/*# sourceMappingURL=alert-dialog.component.css.map */
